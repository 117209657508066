import React, { Component,  } from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";

import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';



Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class btob  extends Component {

 
    constructor(props) {
         super(props);
       // console.log("<=--=>" + props.data.allWordpressPage.nodes[0].acf.conference_link);
    }
 
    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.location.href = this.props.data.allWordpressPage.nodes[0].acf.conference_link
        }
    }

    render(){ 
        const conference_link = this.props.data.allWordpressPage.nodes[0].acf.conference_link;
        const post = this.props.data.allWordpressPage.nodes[0];
        const metatitle = post.acf.meta_title ;
        const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 

 return null;
 
        return(



            <div>
                
                <Layout>
                   <Helmet>
                   <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          

                </Helmet>
 {/*canonical_url*/}

            <section class="countrie-banner-sec-hgttr-privacy">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="countrie-banner-inner-agrs">
                                <ol class="breadcrumb-prfs">
                                    <li class="breadcrumb-item"><a href="/">Home </a></li> 
                                    <li class="breadcrumb-item active"> {this.props.data.allWordpressPage.nodes[0].title}</li>
                                </ol>
                                <div class="text-box-urds wow fadeInUp animated" data-wow-duration="1s" data-wow-delay=".1s">
                                    <h3>The WorldGrad | {this.props.data.allWordpressPage.nodes[0].title}</h3>
                                    <p>{this.props.data.allWordpressPage.nodes[0].title}</p>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section class="scrds-dvtr-dnlr-ernd" id="privacy-policy-id-head">

            <div class="container">
             
            <div class="col-md-12 flt-lft"> 
                            <div class="enquire-btn">
                               <a class="btn-default conference_link" href={conference_link} target="_blank">Click here to join Virual office</a>
                            </div>  
                        </div>
                       </div>
                    </section>
                </Layout>
            </div>   
        )
    }
}

export default btob;
export const btobQuery = graphql`
query btobQuery {
    allWordpressPage(filter: {wordpress_id: {in: 2570}}) {
      nodes { 
        title
        wordpress_id
        acf {
            conference_link
meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
        }
    }
    }
}`
